import React, { useEffect } from 'react'
import { useLocalStore } from 'mobx-react-lite'
import { runInAction } from 'mobx'
import { Hub } from '@aws-amplify/core'
import Auth from '@aws-amplify/auth'

function createAppStore() {
  return {
    reset() {
      runInAction(() => {
        this.user = null
        this.hasCustomerInfo = false
        this.newsletter = null
        this.isSubscribed = null
        this.cancelAtPeriodEnd = null
        this.subscriptionDollarAmount = null
        this.hasPaymentMethod = null
        this.last4 = null
        this.nextInvoiceDate = null
        this.isUnpaid = null
      })
      return this;
    },

    async fetch(path, method, data) {
      if (!this.user)
        throw new Error('User is not authenticated')
      let idToken = (await Auth.currentSession()).idToken.jwtToken
      let result = await fetch(process.env.GATSBY_REST_API + path, {
        method: method, 
        headers: new Headers({ 
          'Authorization': 'Bearer ' + idToken,
          'Content-Type': 'application/json'
        }),
        body: data ? JSON.stringify(data) : null
      })
      let json = await result.json()
      if (!json.success)
        throw new Error(json.errorMessage)
      return json;
    },

    async setPaymentMethod(paymentMethodId) {
      if (!this.hasCustomerInfo)
        throw new Error('Customer info has not been fetched')
      await this.fetch('/me/payment', 'post', { paymentMethodId: paymentMethodId })
    },

    async setSubscription(dollarAmount) {
      if (!this.hasCustomerInfo)
        throw new Error('Customer info has not been fetched')
      await this.fetch('/me/subscription', 'post', { dollarAmount: dollarAmount })
    },

    async cancelSubscriptionImmediately() {
      await this.fetch('/me/subscription', 'post', { cancelImmediately: true })
    },

    async cancelSubscriptionAtPeriodEnd() {
      await this.fetch('/me/subscription', 'post', { cancelAtPeriodEnd: true })
    },

    async setNewsletter(newsletter) {
      await Auth.updateUserAttributes(this.user, { 'custom:newsletter': newsletter ? 'true' : 'false' })
    },

    async refreshCustomerInfo() {
      let json = await this.fetch('/me/account', 'get')
      runInAction(() => {
        this.hasCustomerInfo = true
        this.newsletter = json.newsletter
        this.isSubscribed = json.isSubscribed
        this.cancelAtPeriodEnd = json.cancelAtPeriodEnd
        this.last4 = json.last4
        this.nextInvoiceDate = json.nextInvoiceDate ? new Date(Date.parse(json.nextInvoiceDate)) : null
        this.hasPaymentMethod = json.hasPaymentMethod
        this.subscriptionDollarAmount = json.subscriptionDollarAmount
        this.isUnpaid = json.isUnpaid
      })
    }
  }.reset()
}

const storeContext = React.createContext(null)

export const AppStoreProvider = ({ children }) => {
  const store = useLocalStore(createAppStore)

  // keep our user object updated
  useEffect(() => {
    const updateUser = async authState => {
      try {
        store.user = await Auth.currentAuthenticatedUser()
      } catch {
        store.reset()
      }
    }
    Hub.listen('auth', updateUser)
    updateUser() // check manually the first time because we won't get a Hub event
    return () => Hub.remove('auth', updateUser)
  }, [])

  return <storeContext.Provider value={store}>{children}</storeContext.Provider>
}

export const useAppStore = () => {
  const store = React.useContext(storeContext)
  if (!store) {
    throw new Error('Trying to call useAppStore() outside of <AppStoreProvider>.')
  }
  return store
}