// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-account-js": () => import("./../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-starsteaders-js": () => import("./../src/pages/starsteaders.js" /* webpackChunkName: "component---src-pages-starsteaders-js" */),
  "component---src-pages-terms-js": () => import("./../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-whatisthis-js": () => import("./../src/pages/whatisthis.js" /* webpackChunkName: "component---src-pages-whatisthis-js" */),
  "component---src-templates-news-page-template-js": () => import("./../src/templates/NewsPageTemplate.js" /* webpackChunkName: "component---src-templates-news-page-template-js" */)
}

