import { createMuiTheme } from '@material-ui/core/styles'
import purple from '@material-ui/core/colors/purple';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Ubuntu',
      'Source Sans Pro', 
      'sans-serif'
    ].join(','),
    fontSize: 16
  },
  palette: {
    text: {
      // primary: "#ffffff"
    },
    primary: { main: '#051320' },
    secondary: { main: '#cf7af1' },
    background: {
      default: '#051320'
    }
  },
})

export default theme