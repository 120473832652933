import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider, makeStyles } from '@material-ui/styles'
import theme from '../../src/theme'
import Auth from '@aws-amplify/auth'
import config from '../../src/aws-exports'
import { AppStoreProvider } from '../../src/components/AppStore'
import { StripeProvider } from 'react-stripe-elements'
import { SnackbarProvider } from 'notistack'
import TopBar from '../../src/components/TopBar'

config.Auth = {
  region: process.env.GATSBY_AUTH_REGION,
  userPoolId: process.env.GATSBY_USER_POOL,
  userPoolWebClientId: process.env.GATSBY_USER_POOL_WEB_CLIENT_ID
}
Auth.configure(config)

const useStyles = makeStyles(theme => ({
  snackbarError: { color: '#ffffff' },
  snackbarSuccess: { color: '#ffffff' }
}))

export default function TopLayout(props) {
  const classes = useStyles()

  // load stripe
  const [stripe, setStripe] = useState(null)
  useEffect(() => {
    // I found stripe's suggested method to be full of race conditions, so we're doing this instead
    const checkForStripe = () => {
      if (stripe)
        return;
      if (!window.Stripe) {
        setTimeout(checkForStripe, 10)
        return;
      }
      setStripe(window.Stripe(process.env.GATSBY_STRIPE_KEY))
    }
    setTimeout(checkForStripe, 10)
  })

  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,500,700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Ubuntu:400,500,700&display=swap"
          rel="stylesheet"
        />
        <title>Jellygon</title>
        <script id="stripe-js" src="https://js.stripe.com/v3/" async></script>
        <style type="text/css">{`
          html {
            scroll-behavior: smooth;
          }
          html,body {
            min-height: 100vh;
            display: flex;
            flex-direction: column;
          } 
          #___gatsby {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
          }
          div[role="group"][tabindex] {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
          }
          a {
            color: #cf7af1
          }
        `}</style>
      </Helmet>
      <AppStoreProvider>
        <StripeProvider stripe={stripe}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={1} classes={{ variantError: classes.snackbarError, 
                  variantSuccess: classes.snackbarSuccess }} autoHideDuration={5000}>
              <CssBaseline />
              <TopBar className={classes.topBar} />
              {props.children}
            </SnackbarProvider>
          </ThemeProvider>
        </StripeProvider>
      </AppStoreProvider>
    </React.Fragment>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};