import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Icon from '@material-ui/core/Icon'
import jellygonLogo from '../images/jellygon_logo_cursive_small.png'
import discordIcon from '../images/Discord-Logo-White.svg'
import twitterIcon from '../images/Twitter_Social_Icon_Circle_White.svg'
import LinkButton from './LinkButton'
import Auth from '@aws-amplify/auth'
import { navigate } from 'gatsby'
import { useAppStore } from './AppStore'
import { observer } from 'mobx-react-lite'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import Slide from '@material-ui/core/Slide'

const TopBarLinkButton = props => {
  const styles = useStyles()
  return <LinkButton className={styles.button} {...props} />
}

const TopBarIconButton = props => {
  const styles = useStyles()
  return <IconButton className={styles.button} {...props} />
}

const useStyles = makeStyles(theme => ({
  bar: {
    borderBottom: "1px solid #2f404a"
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  logo: {
    height: "50px",
    marginTop: "15px",
    marginBottom: "10px",
    paddingLeft: "5px",
    paddingRight: "5px"
  },
  iconRoot: {
    textAlign: 'center',
    lineHeight: "initial"
  },
  list: {
    width: "auto"
  },
  button: {
    color: "#21CAD2", 
    borderRadius: "0", 
    padding: "0 15px",
    '&:hover': {
      color: '#A569E3'
    }
  }
}));

function HideOnScroll(props) {
  const trigger = useScrollTrigger();
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {props.children}
    </Slide>
  );
}

const TopBar = observer(() => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const appStore = useAppStore()
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false)

  const isDesktopAccountMenuOpen = Boolean(anchorEl)

  function handleDesktopAccountMenuOpen(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleMenuClose() {
    setAnchorEl(null)
  }

  const desktopAccountMenuId = 'desktop-account-menu'
  const desktopAccountMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={desktopAccountMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isDesktopAccountMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => { handleMenuClose(); navigate("/account") }}>My account</MenuItem>
      <MenuItem onClick={() => { handleMenuClose(); Auth.signOut() }}>Logout</MenuItem>
    </Menu>
  );

  const user = appStore.user

  const mobileMenu = () => (
    <div className={classes.list} role="presentation" onClick={toggleMobileMenu(false)}
        onKeyDown={toggleMobileMenu(false)}>
      <List>
        {(user !== null) ?
          <ListItem>
            <ListItemText primary={"Hi, " + user.username + "!"} style={{color: "#aaaaaa"}} />
          </ListItem>
        : <ListItem button onClick={() => navigate("/account")}>
              <ListItemText primary={"Login / Sign Up"} />
            </ListItem>}
        <Divider />
        <ListItem button>
          <ListItemText primary={"Home"} onClick={() => navigate("/")}/>
        </ListItem>
        {/* <ListItem button>
          <ListItemText primary={"What is this?"} onClick={() => navigate("/whatisthis")} />
        </ListItem> */}
        <ListItem button>
          <ListItemText primary={"Starsteaders"} onClick={() => navigate("/starsteaders")}/>
        </ListItem>
        <ListItem button>
          <ListItemText primary={"News"} onClick={() => navigate("/news")}/>
        </ListItem>
        <ListItem button>
          <ListItemText primary={"About"} onClick={() => navigate("/about")}/>
        </ListItem>
      </List>
      <Divider />
      {(user !== null) ?
        <ListItem button onClick={() => navigate("/account")}>
          <ListItemText primary={"My Account"} />
        </ListItem>
      : null}
      {(user !== null) ?
        <ListItem button onClick={() => Auth.signOut()}>
          <ListItemText primary={"Logout"} />
        </ListItem>
      : null}
      <Divider />
      <ListItem button component="a" target="_blank" rel="noopener noreferrer" href="https://discord.gg/U7x8Yum">
        <ListItemText primary={"Join our Discord"} />
      </ListItem>
      <ListItem button component="a" target="_blank" rel="noopener noreferrer" href="https://twitter.com/jellygon">
        <ListItemText primary={"Twitter"} />
      </ListItem>
    </div>
  )

  const toggleMobileMenu = (open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    setMobileMenuOpen(open)
  }

  return (
    <React.Fragment>
      <HideOnScroll>
        <AppBar position="sticky" elevation={0} className={classes.bar}>
          <Toolbar style={{alignItems: "stretch"}}>
            <div className={classes.sectionMobile}>
              <TopBarIconButton edge="start" color="inherit" aria-label="Open Menu" onClick={toggleMobileMenu(true)}>
                <MenuIcon />
              </TopBarIconButton>
              <Drawer anchor="bottom" open={mobileMenuOpen} onClose={toggleMobileMenu(false)}>{mobileMenu()}</Drawer>
            </div>
            <TopBarLinkButton href="/"><img src={jellygonLogo} alt="Jellygon Logo" className={classes.logo} /></TopBarLinkButton>
            <div className={classes.sectionDesktop}>
              {/* <TopBarLinkButton href="/whatisthis">What is this?</TopBarLinkButton> */}
              <TopBarLinkButton href="/starsteaders">Starsteaders</TopBarLinkButton>
              <TopBarLinkButton href="/news">News</TopBarLinkButton>
              <TopBarLinkButton href="/about">About</TopBarLinkButton>
            </div>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <TopBarIconButton href="https://discord.gg/U7x8Yum" target="_blank" rel="noopener noreferrer"><Icon classes={{root: classes.iconRoot}}>
                <img alt="Discord Button" className={classes.imageIcon} src={discordIcon} />
              </Icon></TopBarIconButton>
              <TopBarIconButton href="https://twitter.com/jellygon" target="_blank" rel="noopener noreferrer"><Icon classes={{root: classes.iconRoot}}>
                <img alt="Twitter Button" className={classes.imageIcon} src={twitterIcon} />
              </Icon></TopBarIconButton>
              {(user !== null) ? <TopBarLinkButton href="/account"><span style={{textTransform: "none"}}>Hi, {user.username}!</span></TopBarLinkButton> : null}
              {(user !== null)
                ? <TopBarIconButton
                      edge="end"
                      aria-label="Account of current user"
                      aria-controls={desktopAccountMenuId}
                      aria-haspopup="true"
                      onClick={handleDesktopAccountMenuOpen}
                      color="inherit"
                    >
                      <AccountCircle />
                  </TopBarIconButton>
                : <TopBarLinkButton href="/account">Login / Sign Up</TopBarLinkButton>
              }
            </div>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      {desktopAccountMenu}
    </React.Fragment>
  );
})

export default TopBar